import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

// Size may not be smaller than 14 on desktop
// due to legal reasons
// We have a cease and desist on that
// If in doubt, talk to legal dept.
export const TrustText = styled(Typography)(({ theme }) => ({
  fontSize: '8px',
  color: '#ababab',
  wordBreak: 'break-word',
  lineHeight: '13px',
  margin: `${theme.spacing(1)} 0`,
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    color: '#323232',
    lineHeight: '20px',
  },
  '& a': {
    fontWeight: 500,
    color: '#39A949',
    '&:hover': {
      opacity: 0.75,
    },
  },
}));

export const TvTrustText = styled(Typography)(({ theme }) => ({
  fontSize: '8px',
  color: '#848484',
  wordBreak: 'break-word',
  margin: `${theme.spacing(1)} 0`,
  [theme.breakpoints.up('md')]: {
    fontSize: '12px',
    color: '#848484',
  },
}));

export const LegalText = () => {
  return (
    <>
      <TrustText>
        1 Repr. Bsp. gemäß §6a PAngV: Nettodarlehensbetrag: 1.000 €, Laufzeit:
        24 Monate, 24 Raten zu je 41,49 €, eff. Jahreszins: -0,40 %, gebundener
        Sollzins: -0,40 % p.a., Gesamtbetrag: 995,83 €. SolarisBank AG,
        Cuvrystraße 53, 10997 Berlin.
      </TrustText>
      <TrustText>
        2 Weitere Informationen sowie die Garantie-Bedingungen{' '}
        <a href="https://www.smava.de/kredit/guenstiger">finden Sie hier</a>
      </TrustText>
    </>
  );
};

export const LegalText32Percent = () => {
  return (
    <>
      <TrustText>
        1 Vergleich der eff. Jahreszinssätze neu vergebener Konsumentenkredite
        deutscher Banken (lt. Deutscher Bundesbank) mit den eff. Jahreszinssätze
        von über smava neu vermittelten Konsumentenkredite. Mehr Infos:{' '}
        <a href="https://www.smava.de/kreditvergleich">
          https://www.smava.de/kreditvergleich/
        </a>
      </TrustText>
      <TrustText>
        2 Weitere Informationen sowie die Garantie-Bedingungen{' '}
        <a href="https://www.smava.de/kredit/guenstiger">finden Sie hier</a>
      </TrustText>
    </>
  );
};

export const LegalTextShort = () => {
  return (
    <>
      <TrustText>
        &#42; Weitere Informationen sowie die Garantie-Bedingungen{' '}
        <a href="https://www.smava.de/kredit/guenstiger">finden Sie hier</a>
      </TrustText>
    </>
  );
};
