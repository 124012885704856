import React from 'react';
import { Document, BLOCKS } from '@contentful/rich-text-types';
import { Typography } from '@mui/material';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';

import { expandTextMacros } from 'lib/text-macros/textMacroHelper';

// Note: The renderText method will change all newlines and change them into br tags.
// If that is not done, react will choke on some browsers as the newline will cause a
// plethora of hydration errors as some browser will filter them out and then you have
// a gross mismatch of content.
const defaultOptions: Options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (_, children) => (
      <Typography variant="h1">{children}</Typography>
    ),
    [BLOCKS.HEADING_2]: (_, children) => (
      <Typography variant="h2">{children}</Typography>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <Typography variant="h3">{children}</Typography>
    ),
    [BLOCKS.HEADING_4]: (_, children) => (
      <Typography variant="h4">{children}</Typography>
    ),
    [BLOCKS.HEADING_5]: (_, children) => (
      <Typography variant="h5">{children}</Typography>
    ),
    [BLOCKS.HEADING_6]: (_, children) => (
      <Typography variant="h6">{children}</Typography>
    ),
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Typography variant="body1" marginBottom={2}>
        {children}
      </Typography>
    ),
  },

  renderText: (text) => {
    return text.split('\n').reduce((children: any, textSegment, index) => {
      return [
        ...children,
        index > 0 && <br key={index} />,
        expandTextMacros(textSegment),
      ];
    }, []);
  },
};

interface RichTextProps {
  document?: Document;
  options?: Options;
}

export function RichText(props: RichTextProps) {
  const { document, options = defaultOptions } = props;

  if (!document) return <></>;

  const reactComponents = documentToReactComponents(document, options);
  return <>{reactComponents}</>;
}
